import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FullDialog from './FullDialog';
import ProcessBody from './ProcessBody';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '7rem 3rem ',
    textAlign: 'center',
    borderRadius: '3px',
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '0 .25rem 1rem rgba(0,0,0,0.15)',
    backgroundImage: (handleImage) =>
      `linear-gradient(to bottom,rgba(0,173,183,.5), rgba(0,173,183,.4)), url(${handleImage()})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '2.88rem',
    width: '100%',
    boxSizing: 'border-box',
    transition: 'transform 0.25s',
    '&:hover': {
      boxShadow: '0 .5rem 1.25rem rgba(0,0,0,0.15)',
      transform: 'scale(1.01)',
      cursor: 'pointer',
    },
  },
  cardText: {
    ...theme.typography.headerThree,
    color: theme.palette.primary.common,
    textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white',
  },
}));

const ProcessCard = ({ text, image, body, deliverables, skills, tabIndex }) => {
  const [open, setOpen] = useState(false);
  const handleImage = () => image;
  const classes = useStyles(handleImage);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <div
      className={classes.card}
      role="button"
      tabIndex={tabIndex}
      onClick={handleClickOpen}
      // onKeyDown={handleClickOpen}
    >
      <span className={classes.cardText}>{text}</span>
      <FullDialog open={open} setOpen={setOpen}>
        <ProcessBody
          title={text}
          body={body}
          deliverables={deliverables}
          skills={skills}
        />
      </FullDialog>
    </div>
  );
};

export default ProcessCard;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import ProcessSection from '../components/ProcessSection';
import HeroTwo from '../components/HeroTwo';
import logo from '../images/logo.svg';
import favicon from '../images/favicon.svg';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBlockEnd: '12rem',
  },
}));

const ServicesPage = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      allContentfulService(sort: { fields: createdAt }) {
        edges {
          node {
            title
            category
            skills {
              file {
                fileName
                url
              }
            }
            deliverables
            body {
              raw
            }
            thumbnail {
              file {
                fileName
                url
              }
            }
          }
        }
      }
    }
  `);
  const handleSort = (category) => {
    const categoryItems = data.allContentfulService.edges.filter(
      (edge) => edge.node.category === category
    );
    return categoryItems.map((item) => ({
      title: item.node.title,
      image: item.node.thumbnail.file.url,
      body: item.node.body,
      deliverables: item.node.deliverables,
      skills: item.node.skills,
    }));
  };
  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />
        <meta property="og:title" content="Nabhas Alakkat | Services" />
        <meta property="og:description" content="my web developer portfolio" />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://alakkat.com/services" />
        <title>Nabhas Alakkat | Services</title>
        <link rel="canonical" href="https://alakkat.com/services" />
      </Helmet>
      <HeroTwo title="Services" />
      <ProcessSection title="Research" items={handleSort('research')} />
      <ProcessSection title="Design" items={handleSort('design')} />
      <ProcessSection title="Development" items={handleSort('develop')} />
    </div>
  );
};

export default ServicesPage;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import ProcessCard from './ProcessCard';

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.header,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
  },
  container: {
    marginBlock: '4rem',
  },
}));

const ProcessSection = ({ title, items }) => {
  const classes = useStyles();
  return (
    <div>
      <h2 className={classes.header}>{title}</h2>
      <Container className={classes.container}>
        <Grid container spacing={2} justify="space-evenly">
          {items.map((item, i) => (
            <Grid key={`card-${i}`} item md={4} xs={12}>
              <ProcessCard
                text={item.title}
                image={item.image}
                body={item.body}
                skills={item.skills}
                deliverables={item.deliverables}
                tabIndex={i}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default ProcessSection;

import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Img from 'gatsby-image';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import HeroTwo from './HeroTwo';
import { Bold, Italics, Text } from './ProjectBody';

const useStyles = makeStyles((theme) => ({
  para: {
    maxWidth: '66rem',
    margin: '0 auto',
    '&:first-child': {
      marginTop: '3rem',
    },
  },
  header: {
    ...theme.typography.headerTwo,
    textAlign: 'center',
  },
  headerTwo: {
    ...theme.typography.headerThree,
    color: theme.palette.secondary.main,
  },
  body: {
    ...theme.typography.body,
    textAlign: 'left',
  },
  deliverableContainer: {},
  deliverableGrid: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '3px',
    margin: '1rem',
  },
  delverableText: {
    ...theme.typography.body,
    textAlign: 'left',
  },
  skillWrapper: {
    width: '10rem',
    height: '10rem',
  },
  skill: {
    width: '100%',
    height: 'auto',
    margin: 'auto',
    opacity: 0.6,
  },
  listItem: {
    '&>p': {
      marginBlock: 0,
    },
  },
}));

const Heading = ({ children }) => {
  const classes = useStyles();
  return <h2 className={classes.header}>{children}</h2>;
};
const HeadingTwo = ({ children }) => {
  const classes = useStyles();
  return <h3 className={classes.headerTwo}>{children}</h3>;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italics>{text}</Italics>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      node.content.value !== '' && <Text>{children}</Text>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading>{children}</Heading>,
    [BLOCKS.HEADING_3]: (node, children) => <HeadingTwo>{children}</HeadingTwo>,
    [BLOCKS.LIST_ITEM]: (node, children) => {
      const classes = useStyles();
      return <li className={classes.listItem}>{children}</li>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const classes = useStyles();
      return node.data.target.file.contentType === 'image/svg+xml' ? (
        <img
          className={classes.vector}
          alt={node.data.target.file.fileName}
          src={node.data.target.file.url}
        />
      ) : (
        <div className={classes.resultWrapper}>
          <Img className={classes.result} fluid={node.data.target.fluid} />
        </div>
      );
    },
  },
};

const ProcessBody = ({ title, body, deliverables, skills }) => {
  const classes = useStyles();

  return (
    <div>
      <HeroTwo title={title} marginTop="0rem" />
      <Container>
        <Grid container justify="space-between">
          <Grid item sm={8}>
            <div className={classes.richTextWrapper}>
              {body && renderRichText(body, options)}
            </div>
          </Grid>
          <Grid item sm={3}>
            <h2 className={classes.headerTwo}>Key Deliverables</h2>
            {deliverables.map((d) => {
              return <p className={classes.delverableText}>{d}</p>;
            })}
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="space-evenly" spacing={3}>
          {skills &&
            skills.map((skill) => {
              const skillUrl = skill.file.url;
              return (
                <Grid item sm={skills.length > 6 ? 1 : 2} xs={2}>
                  <img
                    className={classes.skill}
                    alt={skill.file.fileName}
                    src={skillUrl}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </div>
  );
};

export default ProcessBody;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialog: {
    marginTop: '4rem',
    position: 'relative',
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
  },
  close: {
    color: theme.palette.common.white,
    fontSize: '2rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullDialog = ({ open, setOpen, children }) => {
  const classes = useStyles();

  const handleToggle = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleToggle}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton edge="end" color="inherit" onClick={handleToggle}>
          <CloseIcon fontSize="large" className={classes.close} />
        </IconButton>
      </Toolbar>

      {children}
    </Dialog>
  );
};

export default FullDialog;
